<template>
  <div class="">
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <v-btn small color="primary" @click="$nova.gotoLink({path: '/tds/ar#add'})">
            <v-icon left>mdi-plus</v-icon>
            <span>Add New AR Analysis</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="shadow-off" text @click="refreshData()" small><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
        </div>
        <v-simple-table dense class="" v-if="listdata.length > 0">
          <template v-slot:default>
            <thead>
            <tr class="">
              <th scope="slno">SL No</th>
              <th scope="description">Description</th>
              <th scope="start">Start Period</th>
              <th scope="end">End Period</th>
              <th scope="action">Action</th>
              <th scope="status">Status</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(v, k) in listdata" :key="k" @click="$nova.gotoLink({path: '/tds/ar/view/'+v._id})" class="cursor-pointer hoverable">
                <td>{{k+1}}</td>
                <td>
                  <div class="font-weight-bold">
                    {{v.description || ''}} 
                  </div>
                </td>
                <td>
                  <span>{{$nova.formatDate(v.from)}}</span>
                </td>
                <td>
                  <span>{{$nova.formatDate(v.to)}}</span>
                </td>
                <td>
                  <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="view-on-hover-item-d mx-0 px-0" icon v-bind="attrs" v-on="on"><v-icon>mdi-menu</v-icon></v-btn>
                    </template>
                    <v-list dense class="pa-0 border-left-default">
                      <v-list-item v-if="v.stage!==3" @click.stop="addeditproject={...v};editprojectdialog=true;addeditprojecterror={}">
                        <v-list-item-title><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="v.stage===3" @click="openProject(k, v._id)">
                        <v-list-item-title><v-icon left>mdi-autorenew</v-icon>Re-Open</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else @click="closeProject(k, v._id)">
                        <v-list-item-title><v-icon left>mdi-cancel</v-icon>Close</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td>
                  <v-chip small label v-if="v.stage===3" class="body-2" color="success">Closed</v-chip>
                  <v-chip small label text v-else-if="v.stage===1" class="body-2" color="warning">Pending for review</v-chip>
                  <v-chip small label text v-else-if="v.stage===0" class="body-2" color="info">Open</v-chip>
                </td>
              </tr> 
            </tbody>
          </template>
        </v-simple-table>
        <div v-else class="pa-6 grey--text title text-center">
          No projects found
        </div>
        
      </v-card-text>
    </v-card>
        <v-dialog v-model="addprojectdialog" max-width="600" persistent>
          <v-card :loading="addprojectloading" :disabled="addprojectloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Add New AR Analysis</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="addprojectdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.from" label="Period From" :error="addeditprojecterror.from" :max="addeditproject.to"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.to" label="Period To" :error="addeditprojecterror.to" :min="addeditproject.from" :max="$nova.formatDateISO(new Date())"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string v-model="addeditproject.description" label="Description" :error="addeditprojecterror.description"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown label="Associate User(s)" multiple v-model="addeditproject.userlist" :error="addeditprojecterror.userlist" :items="formdata.users" itemtext="name" itemvalue="_id" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="addProject()">Create</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="editprojectdialog" max-width="600" persistent>
          <v-card :loading="editprojectloading" :disabled="editprojectloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Edit AR Analysis</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="editprojectdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.from" label="Period From" :error="addeditprojecterror.from" :max="addeditproject.to"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.to" label="Period To" :error="addeditprojecterror.to" :min="addeditproject.from" :max="$nova.formatDateISO(new Date())"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string v-model="addeditproject.description" label="Description" :error="addeditprojecterror.description"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown label="Associate User(s)" multiple v-model="addeditproject.userlist" :error="addeditprojecterror.userlist" :items="formdata.users" itemtext="name" itemvalue="_id" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="editProject()">Update</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {Chart, PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController,ChartDataLabels);

export default {
  name: 'pbc_project_list',
  // props: ['props'],
  data: function(){
    return {
      generateddescription: "",
      addeditproject: {},
      addeditprojecterror: {},
      addprojectdialog: false,
      formdata: {},
      addprojectloading: false,
      editprojectdialog: false,
      editprojectloading: false,
      loading: false,
      listdata: [],
      charts: [],
      chartdata: {},
    }
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    init(){
      if(this.$route.hash === "#add") {
        this.addeditproject = {};
        this.addeditprojecterror = {};
        this.addprojectdialog = true;
      }
      this.refreshData();
    },
    refreshData() {
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.getData();
    },
    getData() {
      this.loading = true;
      
      return this.axios.post("/v2/tds/ar/project/getformdata/").then(dt => {
        if(dt.data.status === "success"){
          this.formdata = dt.data.data[0] || {};
        }else throw new Error ("error reading form data");
        return this.axios.post("/v2/tds/ar/project/get");
      }).then(dt => {
        this.listdata = [];
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.listdata = dt.data.data;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    addProject() {
      this.addprojectloading = true;
      this.axios.post("/v2/tds/ar/project/add", {data: this.addeditproject}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "New Analysis Created");
          if(dt.data.data.length > 0){
            this.$nova.gotoLink({path: '/tds/ar/view/'+dt.data.data[0]._id})
          }else this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditprojecterror = dt.data.data[0].index0;
          }else throw new Error (dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addprojectloading = false;
      })
    },
    editProject() {
      this.editprojectloading = true;
      this.axios.post("/v2/tds/ar/project/edit/"+this.addeditproject._id, {data: this.addeditproject}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Updated");
          this.editprojectdialog = false;
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditprojecterror = dt.data.data[0].index0;
          }else throw new Error (dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.editprojectloading = false;
      })
    },
    closeProject(k, i) {
      this.loading = true;
      this.axios.post("/v2/tds/ar/project/close/"+i).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Closed");
          this.listdata[k].stage = 3;
          this.listdata.push({});
          this.listdata.pop();
          
          
        }else throw new Error ("Error closing project");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    openProject(k, i) {
      this.loading = true;
      this.axios.post("/v2/tds/ar/project/open/"+i).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Re-opened");
          this.listdata[k].stage = 0;
          this.listdata.push({});
          this.listdata.pop();
         
        }else throw new Error ("Error re-opening project");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    generateDescription(){
      if(this.addeditproject.from && this.addeditproject.to) {
        if(!this.addeditproject.description || this.generateddescription === this.addeditproject.description){
          this.generateddescription = "AR TDS Reco - " + this.$nova.formatDate(this.addeditproject.from) + " to " + this.$nova.formatDate(this.addeditproject.to);
          this.addeditproject.description  = this.generateddescription;
        }
      }
    }
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
    "addeditproject.from": function(){
      this.generateDescription();
    },
    "addeditproject.to": function(){
      this.generateDescription();
    },
    "$route": function(d){
      if(d.hash === "#add") {
        this.addprojectdialog = true;
        this.addeditproject = {};
        this.addeditproject.reminderdays = this.addeditproject.reminderdays ?? 7;
      }
    },
    "addprojectdialog": function(d){
      if(!d) this.$nova.gotoLink({hash: ''});
    }
  }
}
</script>
